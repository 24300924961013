<template>
  <div>
    <div v-if="!this.user.active">
      <v-container>
        <v-card class="pa-6">
          <p class="title">
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.welcomeMessage.hello"
              )
            }}
            {{ this.user.name }}
            {{ this.user.middle_name }}!
          </p>
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.welcomeMessage.title"
              )
            }}
          </p>
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.welcomeMessage.subtitle"
              )
            }}
          </p>
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.welcomeMessage.description"
              )
            }}
          </p>
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.welcomeMessage.informing"
              )
            }}
          </p>
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.welcomeMessage.informing2"
              )
            }}
          </p>
        </v-card>
      </v-container>
    </div>
    <div v-if="this.user.active">
      <v-container class="d-flex flex-xs-column">
        <v-col md="3" class="d-flex flex-column">
          <div>
            <p class="title">
              {{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistDashboard.mainPage.currentClient"
                )
              }}
            </p>
            <p class="align-self-left">{{ currentPatient.name }}</p>
            <p class="title">
              {{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistDashboard.mainPage.clientsProblem"
                )
              }}
            </p>
            <p
              class="align-self-left"
              v-for="patient_problem in currentPatient.patient_problems"
              :key="patient_problem"
            >
              {{ patient_problem }}
            </p>
          </div>

          <p class="title align-self-left">
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.mainPage.rating"
              )
            }}
            <v-rating
              v-model="this.user.rating"
              length="5"
              class="align-self-left"
              background-color="gray"
              color="yellow accent-4"
              half-increments
              readonly
              size="18"
            ></v-rating>
          </p>
        </v-col>
        <v-col md="6" align="center">
          <WebRTCPlayer
            :update-current-client="this.updateCurrentClient"
          ></WebRTCPlayer>

          <v-col class="d-flex flex-column mt-3">
            <v-btn
              class="align-self-center"
              depressed
              outlined
              color="error"
              width="300"
              to="/dashboard/schedule"
              >{{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistDashboard.mainPage.skipNextSession"
                )
              }}</v-btn
            >
          </v-col>
        </v-col>

        <v-col md="3">
          <v-list three-line>
            <v-subheader>{{
              $vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.mainPage.todayClients"
              )
            }}</v-subheader>
            <template v-for="(item, index) in items">
              <v-divider
                v-if="item.divider"
                :key="index"
                :inset="item.inset"
              ></v-divider>

              <v-list-item v-else :key="item.title">
                <v-list-item-content>
                  <v-list-item-title v-html="item.title"></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.subtitle"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-col>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const WebRTCPlayer = () => import("../WebrtcPlayer/WebRTCPlayer");
export default {
  name: "PsyhMainPage",
  components: { WebRTCPlayer },
  metaInfo() {
    return {
      title:
        "Платформа / " + this.$vuetify.lang.t("$vuetify.main.header.menu.main"),
    };
  },
  data() {
    return {
      currentPatient: {},
      currentListPatients: [],
      currentDaypatient: [],
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    getNextSession: function () {
      return this.user.nearest_time_slot;
    },
  },
  created() {
    this.$store.dispatch("user/getProfile", {});
  },
  mounted() {
    this.getListSession();
  },
  methods: {
    updateCurrentClient(patientId) {
      this.user.time_slots[0].slots.map((user) => {
        if (user.id === patientId) {
          Object.assign(this.currentPatient, {
            name: user.patient_name,
            patient_problems: user.patient_problems,
            currentDate:
              this.user.time_slots[0].date.translated + " " + user.time,
          });
        }
      });
    },
    getListSession: function () {
      this.user.time_slots[0].slots.map((slot) => {
        if (slot.patient_name) {
          this.items.push({
            title: slot.patient_name,
            subtitle: slot.time,
          });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
@media (max-width: 600px) {
  .flex-xs-column {
    flex-direction: column;
  }
}
</style>
